import React, { useContext, useState, useEffect } from 'react';
import './AccountPage.css';  // Import the new CSS file
import UserContext from './../../UserContext';

import { BASE_URL } from '../../apiConfig';

const AccountPage = () => {
    const [playerData, setPlayerData] = useState(null);
    const [error, setError] = useState(null);
    const { userInfo } = useContext(UserContext);

    useEffect(() => {
        const fetchAccountData = async () => {
            if (!userInfo || !userInfo.accounts) {
                setError('No account address found.');
                return;
            }

            try {
                const response = await fetch(`${BASE_URL}/get_account`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        account: userInfo.accounts,
                    }),
                });

                const result = await response.json();
                setPlayerData(result.player_data);
            } catch (error) {
                setError('Failed to fetch account data');
            }
        };

        fetchAccountData();
    }, [userInfo]);

    if (error) {
        return <div className="account-container"><p>{error}</p></div>;
    }

    return (
        <div className="account-container">
            <h1 className="account-header">Account Statistics</h1>
            <header>
                <p className="account-address"><strong>Account Address:</strong> {userInfo?.accounts}</p>
            </header>

            <section className="account-info">
                {playerData ? (
                    <div>
                        <p><strong>Account Level:</strong> {playerData[0].player_level}</p>
                        <p><strong>Account Experience:</strong> {playerData[1].player_exp}</p>
                        <p><strong>Games Played:</strong> {playerData[2].player_games_played}</p>
                        <p><strong>Levels Completed:</strong> {playerData[3].player_levels_completed || 0}</p>
                        <p><strong>Deaths:</strong> {playerData[4].player_times_died}</p>
                        <p><strong>Career Armor Lost:</strong> {playerData[5].player_armor_lost}</p>
                        <p><strong>Career Health Lost:</strong> {playerData[6].player_health_lost}</p>
                        <p><strong>Career Kills:</strong> {playerData[7]?.player_kills || 0}</p>
                    </div>
                ) : (
                    <p>Loading player data...</p>
                )}
            </section>
        </div>
    );
};

export default AccountPage;
