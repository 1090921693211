import React, { useState, useEffect } from 'react';
import './CircSupply.css'; // Import the updated CSS
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import config from '../../config/URLs.json';

function formatNumber(num) {
    if (num === "Loading..." || num === "Error") return num;
    return num !== null && num !== undefined
        ? new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(num)
        : 'Error';
}

function SupplyData() {
    const [supplyData, setSupplyData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        console.log('Fetching data...');
        fetch(config.apiUrls.fetchSupplyDataLocal)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok.');
                }
                return response.json();
            })
            .then(data => {
                console.log('Data fetched:', data);
                setSupplyData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setError(true);
                setLoading(false);
            });
    }, []);

    const entries = [
        { label: 'Circulating Supply', key: 'circulating_supply' },
        { label: 'Total in PROX contract', value: 10000000000 }, // Fixed value
        { label: 'Actual Supply', key: 'total_supply' },
        { label: 'Burn Wallet', key: 'total_burn' },
        { label: 'Team Wallet', key: 'total_team' },
        { label: 'Community Wallet', key: 'total_community' },
        { label: 'Foundation Wallet', key: 'total_foundation' },
        { label: 'Rewards Wallet', key: 'total_rewards' },
        { label: 'Ecosystem Development Wallet', key: 'total_ecodev' },
        { label: 'Game Pay wallet', key: 'total_game_pay' },
        { label: 'Armory Wallet', key: 'total_armory_pay' },
        { label: 'Rental Wallet', key: 'total_rental_pay' },
        { label: 'Total in all Team Wallets', key: 'team_wallet_total' },
        { label: 'Total in all Pools', key: 'total_in_pools' },
        { label: 'Total in Pool Distributors', key: 'total_in_pool_distributors' },
        { label: 'Total in All Other Wallets', key: 'total_in_all_other_wallets' },
        { label: 'Total of All Tokens Added', key: 'total_of_all_tokens_added' },
    ];

    const getValue = (key, value) => {
        if (value !== undefined) return value; // Fixed value case
        if (loading) return "Loading...";
        if (error || !supplyData || supplyData[key] === undefined) return "Error";
        return supplyData[key];
    };

    const pieData = {
        labels: entries.map(entry => entry.label),
        datasets: [
            {
                label: 'Supply Data',
                data: entries.map(entry => getValue(entry.key, entry.value) || 0),
                backgroundColor: entries.map((entry, index) =>
                    `hsl(${index * 30}, 70%, 50%)`
                ), // Dynamic colors
                hoverBackgroundColor: entries.map((entry, index) =>
                    `hsl(${index * 30}, 90%, 60%)`
                ),
                borderColor: '#8B0000',
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className="circ-supply-container">
            <div className="chart-container">
                <Pie data={pieData} options={options} />
            </div>
            <h1>PROX TOKENOMICS</h1>
            {supplyData || error ? (
                <div>
                    <ul>
                        <li className="supplyhighlight"><strong>Circulating Supply:</strong> {formatNumber(supplyData?.circulating_supply)}</li>
                        <li>Total in PROX contract: {formatNumber(10000000000)}</li>
                        <li><strong>Actual Supply:</strong> {formatNumber(supplyData?.total_supply)}</li>
                        <li><strong>Burn Wallet:</strong> {formatNumber(supplyData?.total_burn)}</li>
                        <li><strong>Team Wallet:</strong> {formatNumber(supplyData?.total_team)}</li>
                        <li><strong>Community Wallet:</strong> {formatNumber(supplyData?.total_community)}</li>
                        <li><strong>Foundation Wallet:</strong> {formatNumber(supplyData?.total_foundation)}</li>
                        <li><strong>Rewards Wallet:</strong> {formatNumber(supplyData?.total_rewards)}</li>
                        <li><strong>Ecosystem Development Wallet:</strong> {formatNumber(supplyData?.total_ecodev)}</li>
                        <li><strong>Game Pay wallet:</strong> {formatNumber(supplyData?.total_game_pay)}</li>
                        <li><strong>Armory Wallet:</strong> {formatNumber(supplyData?.total_armory_pay)}</li>
                        <li><strong>Rental Wallet:</strong> {formatNumber(supplyData?.total_rental_pay)}</li>
                        <li><strong>Total in all Team Wallets:</strong> {formatNumber(supplyData?.team_wallet_total)}</li>
                        <li><strong>Total in all Pools:</strong> {formatNumber(supplyData?.total_in_pools)}</li>
                        <li><strong>Total in Pool Distributors:</strong> {formatNumber(supplyData?.total_in_pool_distributors)}</li>
                        <li><strong>Total in All Other Wallets:</strong> {formatNumber(supplyData?.total_in_all_other_wallets)}</li>
                        <li><strong>Total of All Tokens Added:</strong> {formatNumber(supplyData?.total_of_all_tokens_added)}</li>
                    </ul>
                </div>
            ) : (
                <div>No data found</div>
            )}
        </div>
    );
}

export default SupplyData;
