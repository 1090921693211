export const filterNFTData = (searchTerm, nftData) => {
    if (!Array.isArray(nftData) || nftData.length === 0) {
        return [];
    }

    const trimmedSearchTerm = searchTerm.trim().toLowerCase();

    if (!trimmedSearchTerm) {
        // Return all data if no search term is provided
        return nftData;
    }

    // Split the search term into individual terms (comma-separated)
    const searchTerms = trimmedSearchTerm.split(',').map(term => term.trim());
    const includeTerms = searchTerms.filter(term => !term.startsWith('-'));
    const excludeTerms = searchTerms
        .filter(term => term.startsWith('-'))
        .map(term => term.slice(1)); // Remove '-' prefix for exclusion terms

    return nftData.filter(nft => {
        // Check for token_id matches if the term starts with a number
        const numberMatch = includeTerms.some(term =>
            /^\d+$/.test(term) && nft.token_id.toString().startsWith(term)
        );

        // Check for trait value matches (inclusion)
        const traitsMatch = includeTerms.every(term =>
            !/^\d+$/.test(term) && // Ignore terms starting with a number for traits
            nft.traits.some(
                trait => trait.value.toLowerCase().includes(term)
            )
        );

        // Check for exclusion terms in trait values
        const excludeMatch = excludeTerms.some(term =>
            nft.traits.some(
                trait => trait.value.toLowerCase().includes(term)
            ) ||
            nft.token_id.toString() === term
        );

        // Include only if it matches inclusion terms and doesn't match exclusion terms
        return (numberMatch || traitsMatch) && !excludeMatch;
    });
};
