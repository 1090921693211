import React, { useEffect, useRef } from 'react';

const StarryBackground = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const width = window.innerWidth;
        const height = window.innerHeight;
        canvas.width = width;
        canvas.height = height;

        let stars = Array(100).fill().map(() => ({
            x: Math.random() * width,
            y: Math.random() * height,
            radius: Math.random() * 1.5 + 0.5,
            alpha: Math.random(),
            decreasing: true,
            dRatio: Math.random() * 0.001 + 0.001,
        }));

        let shootingStars = [];

        function drawStars() {
            ctx.save();
            ctx.clearRect(0, 0, width, height);
            ctx.fillStyle = "#000";
            ctx.fillRect(0, 0, width, height);

            stars.forEach(star => {
                ctx.beginPath();
                ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
                ctx.closePath();
                ctx.fillStyle = `rgba(255, 255, 255, ${star.alpha})`;
                ctx.fill();

                if (star.decreasing) {
                    star.alpha -= star.dRatio;
                    if (star.alpha < 0.2) {
                        star.decreasing = false;
                    }
                } else {
                    star.alpha += star.dRatio;
                    if (star.alpha > 0.9) {
                        star.decreasing = true;
                    }
                }
            });

            shootingStars.forEach((star, index) => {
                if (star.x < 0 || star.x > width || star.y < 0 || star.y > height) {
                    shootingStars.splice(index, 1);
                } else {
                    ctx.beginPath();
                    ctx.moveTo(star.x, star.y);
                    ctx.lineTo(star.x + star.length * star.dirX, star.y + star.length * star.dirY);
                    ctx.strokeStyle = `rgba(255, 255, 255, ${star.opacity})`;
                    ctx.lineWidth = star.radius;
                    ctx.stroke();

                    star.x += star.speed * star.dirX;
                    star.y += star.speed * star.dirY;
                    star.opacity -= 0.01;
                }
            });

            ctx.restore();
            requestAnimationFrame(drawStars);
        }

        function addShootingStars() {
            const count = Math.floor(Math.random() * 0) + 1; //Adjust for amount of shooting stars
            for (let i = 0; i < count; i++) {
                const side = Math.random() < 0.5 ? 1 : -1;
                shootingStars.push({
                    x: side < 0 ? width : 0,
                    y: Math.random() * height,
                    length: Math.random() * 20 + 10,
                    radius: Math.random() * 2 + 1,
                    speed: Math.random() * 5 + 5,
                    dirX: side,
                    dirY: Math.random() * 0.2 - 0.1,
                    opacity: 1
                });
            }
        }

        drawStars();
        setInterval(addShootingStars, Math.random() * 3000 + 10000);

        return () => {
            cancelAnimationFrame(drawStars);
            clearInterval(addShootingStars);
        };
    }, []);

    return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} />;
};

export default StarryBackground;
