import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './GamePage.css';
import { GAME_URL } from '../../apiConfig';

const LOAD_LOCAL_WEBGL = false; // Set this to false to load from GitHub

function GamePage() {
    const gameContainerRef = useRef(null);
    const iframeRef = useRef(null);

    const gameSrc = LOAD_LOCAL_WEBGL
        ? "/gamebuild/index.html"
        : GAME_URL;

    useEffect(() => {
        const handleResize = () => {
            if (gameContainerRef.current) {
                gameContainerRef.current.style.width = `${window.innerWidth}px`;
                gameContainerRef.current.style.height = `${window.innerHeight}px`;
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial resize to set dimensions
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="game-page-container" ref={gameContainerRef}>
            <Helmet>
                <title>Game Page</title>
            </Helmet>
            <iframe
                ref={iframeRef}
                title="Proxima Game"
                src={gameSrc}
                frameBorder="0"
                allowFullScreen
                className="game-iframe"
            />
        </div>
    );
}

export default GamePage;
