const multiVACNetwork = {
    chainId: '62621',
    chainName: 'MultiVAC Mainnet',
    nativeCurrency: {
        name: 'MultiVAC',
        symbol: 'MTV',
        decimals: 18,
    },
    rpcUrls: ['https://rpc.mtv.ac'],
    blockExplorerUrls: ['https://e.mtv.ac'],
};

export default multiVACNetwork;