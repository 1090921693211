import React, { useState, useEffect, useContext } from 'react';
import './FightersMount.css'; // Import the updated CSS
import { BASE_URL } from '../../../../apiConfig';
import UserContext from '../../../../UserContext';
import MountComponent from './MountComponent';
import UnmountComponent from './UnmountComponent'; // Import the UnmountComponent

const FightersMount = ({ fighter, nftData, globalMount, setGlobalMount }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { userInfo } = useContext(UserContext);
    const [showMountComponent, setShowMountComponent] = useState(false);
    const [showUnmountComponent, setShowUnmountComponent] = useState(false); // State for unmount component
    const [prices, setPrices] = useState({
        mount_ship_price: null,
    });

    useEffect(() => {
        const fetchPrices = async () => {
            const payload = {
                wallet_address: userInfo.accounts,
                token: userInfo.LoginKey,
                page_name: 'mount',
            };

            try {
                const response = await fetch(`${BASE_URL}/get_prices`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const priceData = await response.json();
                if (response.ok) {
                    setPrices({
                        mount_ship_price: priceData.mount_ship_price,
                    });
                } else {
                    setError(priceData.error || 'Failed to fetch prices');
                }
            } catch (error) {
                setError('Failed to connect to the price API');
            } finally {
                setLoading(false);
            }
        };

        fetchPrices();
    }, [userInfo.accounts, userInfo.LoginKey]);

    const handleMount = () => {
        setShowMountComponent(true);
    };

    const handleUnmount = () => {
        setShowUnmountComponent(true);
    };

    const shouldShowAlreadyMountedMessage =
        globalMount === 'yes' && fighter.is_mounted === 'no';

    const isMountedFighter = fighter.is_mounted === 'yes';

    console.log("Fighter:", fighter);
    console.log("Global Mount:", globalMount);
    console.log("Should Show Already Mounted Message:", shouldShowAlreadyMountedMessage);
    console.log("Is Mounted Fighter:", isMountedFighter);

    return (
        <div>
            {loading ? (
                <p>Loading prices...</p>
            ) : error ? (
                <p className="step-status-message error">{error}</p>
            ) : null}

            {showMountComponent ? (
                <div>
                    <p className="mount-ship-price">
                        Mount Ship Price: {prices.mount_ship_price} PROX
                    </p>
                    <MountComponent
                        fighter={fighter}
                        mount_ship_price={prices.mount_ship_price}
                        setGlobalMount={setGlobalMount}
                    />
                </div>
            ) : showUnmountComponent ? (
                <UnmountComponent fighter={fighter} mount_ship_price={prices.mount_ship_price} />
            ) : (
                <div className="fighters-mount-buttons">
                    {isMountedFighter ? (
                        <button
                            className="fighters-mount-button unmount"
                            onClick={handleUnmount}
                        >
                            Unmount
                        </button>
                    ) : shouldShowAlreadyMountedMessage ? (
                        <p className="already-mounted-message">
                            A Fighter is Already Mounted!
                        </p>
                    ) : (
                        <button className="fighters-mount-button" onClick={handleMount}>
                            Mount
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default FightersMount;
