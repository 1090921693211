import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState(() => {
        // Load initial state from session storage or set default
        const savedData = sessionStorage.getItem('userInfo');
        return savedData ? JSON.parse(savedData) : {};
    });

    useEffect(() => {
        // Save to session storage whenever userInfo changes
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    }, [userInfo]);

    const updateUser = (info) => {
        setUserInfo(prev => ({ ...prev, ...info }));
    };

    return (
        <UserContext.Provider value={{ userInfo, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;