import React from 'react';
import './TopBar.css';

const TopBar = ({ onButtonClick }) => {
    return (
        <div className="topbar-container">
            <button className="topbar-button" onClick={() => onButtonClick('Fighters')}>
                Fighters
            </button>
            <button className="topbar-button" onClick={() => onButtonClick('Bots')}>
                Bots
            </button>
            <button className="topbar-button" onClick={() => onButtonClick('Utility')}>
                Utility
            </button>
            <button className="topbar-button" onClick={() => onButtonClick('Resources')}>
                Resources
            </button>
        </div>
    );
};

export default TopBar;