import React from 'react';
import './HangerTopBar.css';

const TopBar = ({ onButtonClick }) => {
    return (
        <div className="hangertopbar-container">
            <button className="hangertopbar-button" onClick={() => onButtonClick('FightersBay')}>
                Fighters Bay
            </button>
            <button className="hangertopbar-button" onClick={() => onButtonClick('BotsQuarters')}>
                Bots Quarters
            </button>
            <button className="hangertopbar-button" onClick={() => onButtonClick('HangerCargo')}>
                Hanger Cargo
            </button>
        </div>
    );
};

export default TopBar;
