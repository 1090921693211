import React from 'react';
import './SubBar.css';

const SubBar = ({ category, onButtonClick, disabled }) => {
    const buttons = {
        Fighters: ["Tellurian Fighters", "Celestial Fighters", "Machina Fighters"],
        Bots: ["Tellurian Bots", "Celestial Bots", "Machina Bots", "Satoshi Bots"],
        Resources: ["Basic Ores", "Refined Materials", "Game Objects"],
        Utility: ["Refinery"],
    };

    return (
        <div className="subbar-container">
            {buttons[category]?.map((item, index) => (
                <button
                    key={index}
                    className={`subbar-button ${disabled ? "disabled" : ""}`}
                    onClick={() => !disabled && onButtonClick(item)}
                    disabled={disabled} // Disable the button if `disabled` is true
                >
                    {item}
                </button>
            ))}
        </div>
    );
};

export default SubBar;