import React from 'react';
import './HangerSubBar.css';

const SubBar = ({ category, onButtonClick, disabled }) => {
    const buttons = {
        FightersBay: ["Overview", "Available Fighters", "Equipment"],
        BotsQuarters: ["Pilot", "Available Crew"],
        HangerCargo: ["Cargo Stats", "Manage Inventory"], // Added for consistency
    };

    return (
        <div className="hangersubbar-container">
            {buttons[category]?.map((item, index) => (
                <button
                    key={index}
                    className={`hangersubbar-button ${disabled ? "disabled" : ""}`}
                    onClick={() => !disabled && onButtonClick(item)}
                    disabled={disabled} // Disable the button if `disabled` is true
                >
                    {item}
                </button>
            ))}
        </div>
    );
};

export default SubBar;
