import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Subscription.css';
import useConnectToMetaMask from './Hooks/ConnectToMetamask';
import useCheckAllowance from './Hooks/CheckAllowance';
import useSubscription from './Hooks/HandleSubscription';
import abi from './Hooks/general_abi';
import subContract from './config/SubscriptionContract';

import { BASE_URL } from './apiConfig';

function Subscription() {
    const navigate = useNavigate();
    const { isConnected, connect, account, web3, error } = useConnectToMetaMask();
    const [isApproved, setIsApproved] = useState(false);
    const [subscriptionSuccessful, setSubscriptionSuccessful] = useState(false);
    const checkAllowance = useCheckAllowance(account, setIsApproved);
    const subscribe = useSubscription();

    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);  // Initial state set to false

    useEffect(() => {
        if (account && web3) {
            setIsLoading(true);  // Set loading to true once account is available
            const checkSubscriptionStatus = async () => {
                const response = await fetch(`${BASE_URL}/check_subscriber?address=${account}`);
                const data = await response.json();
                setIsSubscribed(data.is_subscriber);
                setIsLoading(false);
            };

            checkSubscriptionStatus();
        }
    }, [account, web3]);  // Dependency on account and web3 to delay the effect

    useEffect(() => {
        if (account && web3) {
            checkAllowance().then(allowance => console.log(`Checked allowance: ${allowance}`));
        }
    }, [account, web3, checkAllowance]);

    // Early returns for loading and already subscribed states
    if (isLoading) {
        return <p>Loading...</p>;
    }
    if (isSubscribed) {
        return (
            <div className="subscription already-subscribed">
                <p>ALREADY SUBSCRIBED!</p>
            </div>
        );
    }
    const handleApprove = async () => {
        if (!web3 || !account) {
            console.log("Web3 is not initialized or no account is connected.");
            return;
        }
        const proxContract = new web3.eth.Contract(abi, '0x9029d1dEDa00e166De55406eAB2608E4efC66D7b');
        const amountToApprove = web3.utils.toWei('30000', 'ether');

        try {
            const gasPrice = await web3.eth.getGasPrice();
            await proxContract.methods.approve(subContract.subscriptionContract, amountToApprove).send({
                from: account,
                gasPrice: gasPrice
            });
            console.log('Tokens approved successfully.');
            setIsApproved(true);
        } catch (error) {
            console.error('Error approving tokens:', error);
        }
    };

    const handleSubscribe = async () => {
        const success = await subscribe(account);
        if (success) {
            console.log("Subscription successful!");
            setSubscriptionSuccessful(true);
        } else {
            console.log("Subscription failed!");
        }
    };

    return (
        <div className="subscription">
            <h1>Subscription Benefits</h1>
            <p className="subscription-details">
                Subscribe and enjoy exclusive benefits.
                <br /> Subscription length: 30 days
                <br /> - Unlimited access to the Proxima ecosystem
                <br /> - Early access to new updates, events, and more.
            </p>
            {!isConnected ? (
                <button onClick={connect} className="connect-wallet-btn">Connect Wallet</button>
            ) : (!isApproved ? (
                <button onClick={handleApprove} className="approve-btn">Approve Contract</button>
            ) : subscriptionSuccessful ? (
                <p>Subscription Successful, Please go back and login.</p>
            ) : (
                <button onClick={handleSubscribe} className="subscribe-btn">Subscribe</button>
            ))}
            <button onClick={() => navigate('/')} className="go-back-btn">Go Back</button>
            {error && <p className="error">{error}</p>}
        </div>
    );
}

export default Subscription;
