import React from 'react';

const FightersSell = ({ fighter }) => {
    const handleSell = () => {
        console.log(`Selling Fighter ID: ${fighter.token_id}`);
        // Implement sell logic here
    };

    const handleCancelSale = () => {
        console.log(`Canceling sale for Fighter ID: ${fighter.token_id}`);
        // Implement cancel sale logic here
    };

    return (
        <div>
            <h4>Sell Actions</h4>
            <button onClick={handleSell}>Sell</button>
            <button onClick={handleCancelSale}>Cancel Sale</button>
        </div>
    );
};

export default FightersSell;