import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { useHandleLogin } from './Hooks/handleLogin';
import gearImage from './Images/gear.png';
import logoImage from './headLogo.png';

function App() {
    const handleLogin = useHandleLogin();
    const navigate = useNavigate();

    const handleSubscription = () => navigate('/subscription');
    const handlePlayFree = () => navigate('/freeplay');

    return (
        <div className="App-front">
            <div className="mainsidebar">
                <div className="logo-container">
                    <img src={gearImage} alt="Rotating Gear" className="rotating-gear" />
                    <img src={logoImage} alt="Proxima Universe Logo" className="logo" />
                </div>
                <button className="menu-button-login" onClick={handleLogin}>Login</button>
                <button className="menu-buttonPlayFree" onClick={handlePlayFree}>Play Free</button>
                <hr className="app-divider" />
                <button className="menu-buttonSubsciption" onClick={handleSubscription}>Subscription</button>
                <button className="menu-button2">Buy Prox</button>
                <button className="menu-button2">About Proxima</button>
                <button className="menu-button2">Link Tree</button>
            </div>
            <div className="content-area">
                {/* Removed SubBar and FightersPage */}
            </div>
        </div>
    );
}

export default App;
