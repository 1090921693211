import React, { useState } from 'react';
import './BotsActionsPage.css';
import BotsMount from './BotsMount/BotsMount';
import BotsRent from './BotsRent/BotsRent';
import BotsTransfer from './BotsTransfer/BotsTransfer';
import BotsSell from './BotsSell/BotsSell';

const BotsActionsPage = ({ fighter }) => {
    const [selectedTab, setSelectedTab] = useState(''); // Track the selected tab

    const tabs = ['Mount', 'Rent', 'Transfer', 'Sell'];

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'Mount':
                return <BotsMount fighter={fighter} />;
            case 'Rent':
                return <BotsRent fighter={fighter} />;
            case 'Transfer':
                return <BotsTransfer fighter={fighter} />;
            case 'Sell':
                return <BotsSell fighter={fighter} />;
            default:
                return <p className="bots-select-action-message">Select Action</p>;
        }
    };

    return (
        <div className="bots-actions-page">
            <div className="bots-actions-tabs">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`bots-tab-button ${selectedTab === tab ? 'bots-active-tab' : ''}`}
                        onClick={() => setSelectedTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className="bots-actions-content">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default BotsActionsPage;