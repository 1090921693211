import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../../UserContext';
import './BotsPage.css';
import { BASE_URL } from '../../../apiConfig';
import DetailsPage from './BotsDetailsPage';
import ActionsPage from './BotsActionsPage';

const collectionMapping = {
    "Tellurian Bots": "tellurian_bot",
    "Celestial Bots": "celestial_bot",
    "Machina Bots": "machina_bot",
    "Satoshi Bots": "satoshi_bot",
};

const BotsPage = ({ subCategory, setLoading }) => {
    const [nftData, setNftData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFighter, setSelectedFighter] = useState(null);
    const [contractAddress, setContractAddress] = useState('');
    const [searchError, setSearchError] = useState(false); // New state for search error
    const { userInfo } = useContext(UserContext);

    useEffect(() => {
        if (!subCategory) return;

        setNftData([]);
        setFilteredData([]);
        setSearchError(false); // Reset error state before fetching

        const collectionName = collectionMapping[subCategory] || subCategory.toLowerCase();

        const fetchNFTs = async () => {
            try {
                const requestBody = {
                    wallet_address: userInfo.accounts,
                    token: userInfo.LoginKey,
                    collection_name: collectionName,
                };

                const response = await fetch(`${BASE_URL}/get_wallet_asset_details`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const data = await response.json();

                if (!data.token_data || data.token_data.length === 0) {
                    setSearchError(true); // Set error state if no data
                    return;
                }

                setContractAddress(data.contract_address || '');
                const sortedData = (data.token_data || []).sort(
                    (a, b) => parseInt(a.token_id) - parseInt(b.token_id)
                );

                setNftData(sortedData);
                setFilteredData(sortedData);
            } catch (error) {
                console.error('Failed to fetch Assets:', error);
                setSearchError(true); // Set error state on failure
            } finally {
                if (typeof setLoading === 'function') {
                    setLoading(false);
                }
            }
        };

        fetchNFTs();
    }, [subCategory, userInfo.accounts, userInfo.LoginKey, setLoading]);

    const handleFighterClick = (fighter) => {
        const collectionName = collectionMapping[subCategory] || subCategory.toLowerCase();

        setSelectedFighter({
            ...fighter,
            wallet_address: userInfo.accounts,
            collection_name: collectionName,
            contract_address: contractAddress,
            token: userInfo.LoginKey,
            is_mounted: fighter.is_mounted || 'no',
            is_rented: fighter.is_rented || 'no',
        });
    };

    useEffect(() => {
        const trimmedSearchTerm = searchTerm.trim().toLowerCase();

        if (!trimmedSearchTerm) {
            setFilteredData(nftData); // Show all data if the search term is empty
            return;
        }

        // Split the search term into individual terms (comma-separated)
        const searchTerms = trimmedSearchTerm.split(',').map(term => term.trim());

        const filtered = nftData.filter(nft => {
            // Check for NFT ID match
            const idMatch = searchTerms.some(term => nft.token_id.toString() === term);

            // Check for trait matches
            const traitsMatch = searchTerms.every(term =>
                nft.traits.some(
                    trait =>
                        trait.trait_type.toLowerCase().includes(term) ||
                        trait.value.toLowerCase().includes(term)
                )
            );

            // Return true if either ID matches or all traits match
            return idMatch || traitsMatch;
        });

        setFilteredData(filtered);
    }, [searchTerm, nftData]);

    const getStatusClass = (nft) => {
        if ((nft.is_mounted || '').trim().toLowerCase() === 'yes') return 'fighter-status-mounted';
        if ((nft.is_rented || '').trim().toLowerCase() === 'yes') return 'fighter-status-rented';
        return 'bots-status-default';
    };
    const getCollectionLabel = () => {
        const labelPrefix = subCategory.split(" ")[0];
        return `${labelPrefix} ProxBot ID`;
    };

    return (
        <div className="bots-page">
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search by ID, traits (e.g., 42 or Scud, Ruby, MultiVAC, Ghost)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="bots-content-layout">
                <div className="bots-column1">
                    {searchError ? (
                        <p className="bots-apierror-message">Search Failed</p>
                    ) : filteredData.length === 0 ? (
                        <p className="bots-loading-message">Please Wait.... Searching Assets...</p>
                    ) : (
                        filteredData.map((nft, index) => (
                            <div
                                key={`${nft.token_id}-${index}`}
                                className={"bots-container"}
                                onClick={() => handleFighterClick(nft)}
                            >
                                <h3 className="bots-id">{getCollectionLabel()}: {nft.token_id}</h3>
                                <div className={`bots-status ${getStatusClass(nft)}`}>
                                    {nft.is_mounted?.trim().toLowerCase() === 'yes' && <p>Mounted</p>}
                                    {nft.is_rented?.trim().toLowerCase() === 'yes' && <p>Rented</p>}
                                    {nft.is_mounted?.trim().toLowerCase() !== 'yes' && nft.is_rented?.trim().toLowerCase() !== 'yes' && <p>In Wallet</p>}
                                </div>

                                <div className="bots-traits">
                                    <div className="bots-traits-grid">
                                        {nft.traits.map((trait, traitIndex) => (
                                            <div key={traitIndex} className="bots-trait-item">
                                                <div className="bots-trait-name">{trait.trait_type}</div>
                                                <div className="bots-trait-value">{trait.value}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div className="bots-column2">
                    <div className="bots-details">
                        {selectedFighter ? (
                            <DetailsPage
                                fighter={selectedFighter}
                                traits={selectedFighter.traits}
                            />
                        ) : (
                            'Please Select ProxBot'
                        )}
                    </div>
                    <div className="bots-actions">
                        {selectedFighter ? (
                            <ActionsPage fighter={selectedFighter} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BotsPage;
