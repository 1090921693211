import React, { useState, useContext } from 'react';
import './MountComponent.css';
import BN from 'bn.js';
import Web3 from 'web3';
import UserContext from '../../../../UserContext';
import { BASE_URL, MTV_NETWORK, FighterMOUNT_CONTRACT, PROX_TOKEN } from '../../../../apiConfig';
import { CONTRACT_ABI, ERC721_ABI, ERC20_ABI } from './MountAbi';

const MountComponent = ({ fighter, mount_ship_price, setGlobalMount }) => {
    const {
        contract_address = '',
        token_id = 0,
        collection_name = '',
        wallet_address = '',
    } = fighter || {};

    const [stepStatus, setStepStatus] = useState('');
    const [showButton, setShowButton] = useState(true); // New state to control button visibility
    const { userInfo } = useContext(UserContext);
    const [apiError, setApiError] = useState(null);

    console.log('Raw Fighter Object:', fighter);

    if (!fighter || !contract_address || !token_id) {
        return <p>Loading fighter data...</p>;
    }

    const handleMount = async () => {
        try {
            setShowButton(false); // Hide the button once clicked
            if (!window.ethereum) throw new Error('MetaMask is not installed!');

            const web3 = new Web3(window.ethereum);

            console.log('Switching to MultiVAC network...');
            setStepStatus('Switching to MultiVAC network...');
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${MTV_NETWORK.chainId.toString(16)}` }],
            });

            const accounts = await web3.eth.getAccounts();
            const account = accounts[0];
            if (!account) throw new Error('No account found. Please log in to MetaMask.');

            console.log('Connected Account:', account);

            if (account.toLowerCase() !== wallet_address.toLowerCase()) {
                console.error('Mismatch:', { account, wallet_address });
                throw new Error('Connected MetaMask account does not match the fighter�s owner address.');
            }

            const contract = new web3.eth.Contract(CONTRACT_ABI, FighterMOUNT_CONTRACT);
            console.log('Mount Contract Instance:', contract);

            const proxTokenContract = new web3.eth.Contract(ERC20_ABI, PROX_TOKEN);
            console.log('PROX Token Contract Instance:', proxTokenContract);
            const proxValue = web3.utils.toWei((mount_ship_price || 0).toString(), 'ether');

            setStepStatus('Checking PROX token allowance...');
            const allowance = await proxTokenContract.methods.allowance(account, FighterMOUNT_CONTRACT).call();


            // Convert allowance and proxValue to BN for accurate comparison
            const allowanceBN = new BN(allowance);
            const proxValueBN = new BN(proxValue);



            if (allowanceBN.lt(proxValueBN)) {
                console.log('Allowance insufficient, initiating approval...');
                setStepStatus('Approving PROX token...');
                await proxTokenContract.methods.approve(FighterMOUNT_CONTRACT, proxValue).send({
                    from: account,
                    gasPrice: await web3.eth.getGasPrice(),
                });
            } else {
                console.log('Sufficient allowance found. Skipping approval.');
            }

            const nftContract = new web3.eth.Contract(ERC721_ABI, contract_address);
            console.log('NFT Contract Instance:', nftContract);

            const approvedAddress = await nftContract.methods.getApproved(token_id).call();
            console.log('Approved Address for Token ID:', { token_id, approvedAddress });

            if (approvedAddress.toLowerCase() !== FighterMOUNT_CONTRACT.toLowerCase()) {
                setStepStatus('Approving NFT transfer...');
                await nftContract.methods.approve(FighterMOUNT_CONTRACT, token_id).send({
                    from: account,
                    gasPrice: await web3.eth.getGasPrice(),
                });
            }

            setStepStatus('Sending mount transaction...');
            const gasEstimate = await contract.methods
                .mountNFT(contract_address, token_id)
                .estimateGas({ from: account });
            console.log('Gas Estimate:', gasEstimate);

            const gasPrice = await web3.eth.getGasPrice();
            await contract.methods.mountNFT(contract_address, token_id).send({
                from: account,
                gas: gasEstimate,
                gasPrice,
            });

            setStepStatus('Transaction sent! Waiting for confirmation...');

            const response = await fetch(`${BASE_URL}/mount_ship`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    wallet_address: account,
                    collection_name,
                    nft_id: token_id,
                    token: userInfo.LoginKey,
                }),
            });

            if (response.ok) {
                setStepStatus('Fighter mounted successfully! Please check Hanger.');
                setGlobalMount('yes'); // Update globalMount state
            } else {
                throw new Error('Error communicating with the server.');
            }
        } catch (error) {
            console.error('Error mounting NFT:', error);
            setApiError(error.message || 'Failed to mount the NFT');
            setStepStatus('Error occurred during mounting.');
            setShowButton(true); // Show the button again if an error occurs
        }
    };


    return (
        <div>
            {stepStatus && (
                <p className={`step-status-message ${apiError ? 'error' : ''}`}>
                    {stepStatus}
                </p>
            )}
            {showButton && (
                <button className="confirm-mount-button" onClick={handleMount}>
                    Confirm Mount
                </button>
            )}
        </div>
    );
};

export default MountComponent;
