import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './FreePlay.css';

function FreePlay() {
    const navigate = useNavigate();
    const gameContainerRef = useRef(null);
    const iframeRef = useRef(null);

    return (
        <div className="fgame-page-container">
            <Helmet>
                <title>Game Page</title>
            </Helmet>

            {/* Top advertisement container */}
            <div className="fad-container">
                <p>Advertisement</p>
            </div>

            <div ref={gameContainerRef} className="fgame-container">
                <iframe
                    ref={iframeRef}
                    title="Proxima Game"
                    src="https://ckproxima.github.io/ProximaGameBuild/"
                    frameBorder="0"
                    allowFullScreen
                    className="fgame-iframe"
                />
            </div>

            {/* Bottom advertisement container */}
            <div className="fad-container">
                <p>Advertisement</p>
            </div>

            {/* Back button */}
            <button className="fback-button" onClick={() => navigate('/')}>
                Back to Home
            </button>
        </div>
    );
}

export default FreePlay;
