import React, { useState } from 'react';
import './ProximaAssets.css';
import TopBar from './TopBar';
import SubBar from './SubBar';
import ResourcesPage from './ResourcePage/ResourcePage';
import FightersPage from './FightersPage/FightersPage';
import BotsPage from './BotsPage/BotsPage';
import UtilityPage from './UtilityPage/UtilityPage';

function AssetPage() {
    const [selectedCategory, setSelectedCategory] = useState("Welcome to Proxima!");
    const [selectedSubCategory, setSelectedSubCategory] = useState(""); // To handle subcategory selection
    const [showSubBar, setShowSubBar] = useState(false); // Control SubBar visibility
    const [showContainers, setShowContainers] = useState(false); // Control NFT containers visibility

    const handleButtonClick = (category) => {
        setSelectedCategory(category);
        setSelectedSubCategory(""); // Reset the subcategory when selecting a new main category
        setShowSubBar(true); // Show the SubBar
        setShowContainers(false); // Hide the containers
    };

    const handleSubBarClick = (subcategory) => {
        setSelectedSubCategory(subcategory); // Update the selected subcategory
        setShowContainers(true); // Show the containers

    };

    const renderPageContent = () => {
        if (!showContainers) {
            return <p className="welcome-message"></p>;
        }

        switch (selectedCategory) {
            case "Fighters":
                return <FightersPage subCategory={selectedSubCategory} />;
            case "Bots":
                return <BotsPage subCategory={selectedSubCategory} />;
            case "Utility":
                return <UtilityPage subCategory={selectedSubCategory} />;
            case "Resources":
                return <ResourcesPage subCategory={selectedSubCategory} />;
            default:
                return <p className="welcome-message">Please select an asset catagory.</p>;
        }
    };

    return (
        <div className="proxima-container">
            <TopBar onButtonClick={handleButtonClick} />
            {showSubBar && (
                <div className="subbar-animated-container">
                    <SubBar category={selectedCategory} onButtonClick={handleSubBarClick} />
                </div>
            )}
            {/* Render the page content */}
            <div className="page-content">
                {renderPageContent()}
            </div>
        </div>
    );
}

export default AssetPage;