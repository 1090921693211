import React, { useEffect, useContext } from "react";
import UserContext from "../UserContext";
import { BASE_URL } from "../apiConfig";
import "./Admin.css";

const AdminPage = () => {
    const { userInfo } = useContext(UserContext);

    useEffect(() => {
        const loadAdminJs = async () => {
            try {
                const wallet_address = userInfo.accounts;
                const token = userInfo.LoginKey;

                if (!wallet_address || !token) {
                    console.error("Missing wallet_address or token in AdminPage.js");
                    return;
                }

                const script = document.createElement("script");
                script.src = `${BASE_URL}/admin_resources/Admin.js?wallet_address=${encodeURIComponent(
                    wallet_address
                )}&token=${encodeURIComponent(token)}`;
                script.async = true;
                document.body.appendChild(script);

                script.onload = () => {
                    console.log("Admin.js loaded successfully");
                };

                script.onerror = () => {
                    console.error("Failed to load Admin.js");
                };
            } catch (error) {
                console.error("Failed to load Admin.js:", error);
            }
        };

        loadAdminJs();
    }, [userInfo.accounts, userInfo.LoginKey]);

    return (
        <div>
            <div id="admin-root"></div>
        </div>
    );
};

export default AdminPage;
