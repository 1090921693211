import React from 'react';
import './UtilityPage.css'; // Optional, for category-specific styles

const UtilityPage = () => {
    return (
        <div className="utility-content-layout">
            <div className="utility-column1">
                <div className="utility-container">Utility 1</div>
                <div className="utility-container">Utility 2</div>
                <div className="utility-container">Utility 3</div>
            </div>
            <div className="utility-column2">
                <div className="utility-details">Utility Details Section</div>
                <div className="utility-actions">Utility Actions Section</div>
            </div>
        </div>
    );
};

export default UtilityPage;