import React, { useState, useContext } from 'react';
import './FightersTransfer.css'; // Create this CSS file
import Web3 from 'web3';
import UserContext from '../../../../UserContext';
import { MTV_NETWORK } from '../../../../apiConfig';
import { ERC721_ABI } from './TransferAbi';

const FightersTransfer = ({ fighter }) => {
    const { userInfo } = useContext(UserContext);
    const [stepStatus, setStepStatus] = useState('');
    const [apiError, setApiError] = useState(null);
    const [receiverAddress, setReceiverAddress] = useState('');
    const [showButton, setShowButton] = useState(true);

    const handleTransfer = async () => {
        try {
            setShowButton(false); // Disable the button once clicked
            if (!window.ethereum) throw new Error('MetaMask is not installed!');

            const web3 = new Web3(window.ethereum);

            setStepStatus('Switching to MultiVAC network...');
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${MTV_NETWORK.chainId.toString(16)}` }],
            });

            const accounts = await web3.eth.getAccounts();
            const senderAccount = accounts[0];
            if (!senderAccount) throw new Error('No account found. Please log in to MetaMask.');

            if (!Web3.utils.isAddress(receiverAddress)) {
                throw new Error('Invalid wallet address entered.');
            }

            if (senderAccount.toLowerCase() !== fighter.wallet_address.toLowerCase()) {
                throw new Error('Connected MetaMask account does not match the fighter�s owner address.');
            }

            setStepStatus('Preparing transfer transaction...');
            const nftContract = new web3.eth.Contract(ERC721_ABI, fighter.contract_address);
            const gasEstimate = await nftContract.methods
                .transferFrom(senderAccount, receiverAddress, parseInt(fighter.token_id))
                .estimateGas({ from: senderAccount });
            const gasPrice = await web3.eth.getGasPrice();

            await nftContract.methods
                .transferFrom(senderAccount, receiverAddress, parseInt(fighter.token_id))
                .send({
                    from: senderAccount,
                    gas: gasEstimate,
                    gasPrice,
                });

            setStepStatus('NFT transferred successfully!');
            console.log('NFT transferred successfully!');
        } catch (error) {
            console.error('Error transferring NFT:', error.message);
            setApiError(error.message || 'Failed to transfer the NFT');
            setStepStatus('Error occurred during transfer.');
            setShowButton(true); // Re-enable the button if an error occurs
        }
    };

    return (
        <div className="fighters-transfer">
            <h4></h4>
            {fighter.is_mounted?.trim().toLowerCase() === 'yes' ? (
                <p className="transfer-error-message">
                    Cannot Transfer, Fighter in Game Contract
                </p>
            ) : (
                <>
                    {stepStatus && (
                        <p className={`transfer-status-message ${apiError ? 'error' : ''}`}>
                            {stepStatus}
                        </p>
                    )}
                    <div className="transfer-input-container">
                        <input
                            type="text"
                            placeholder="Recipient Wallet Address"
                            value={receiverAddress}
                            onChange={(e) => setReceiverAddress(e.target.value)}
                            className="transfer-input"
                        />
                    </div>
                    {showButton && (
                        <button className="confirm-transfer-button" onClick={handleTransfer}>
                            Confirm Transfer
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default FightersTransfer;
