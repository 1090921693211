import React, { useEffect, useState, useContext } from 'react';
import UserContext from './../../UserContext';
import './FighterDetails.css';
import staticImage from './static.gif';

import { BASE_URL } from '../../apiConfig';

const FighterDetails = () => {
    const { userInfo } = useContext(UserContext);
    const [hangerData, setHangerData] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [mountedShip, setMountedShip] = useState(false);
    const [mountedShipData, setMountedShipData] = useState(null);
    const [traits, setTraits] = useState([]);
    const [forceRender, setForceRender] = useState(0);

    useEffect(() => {
        if (!userInfo || !userInfo.accounts || !userInfo.LoginKey) {
            setError('Account or token is missing.');
            setLoading(false);
            return;
        }

        const fetchHangerData = async () => {
            const payload = {
                account: userInfo.accounts,
                token: userInfo.LoginKey
            };

            try {
                const response = await fetch(`${BASE_URL}/get_hanger`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const data = await response.json();
                if (data.mounted_ship) {
                    setMountedShip(true);
                    setMountedShipData({
                        collection_name: data.mounted_ship_data.collection_name,
                        nft_id: data.mounted_ship_data.nft_id
                    });

                    if (data.traits && data.traits.attributes) {
                        setTraits(data.traits.attributes);
                        setForceRender(prev => prev + 1);
                    }

                    fetchImage(userInfo.accounts, data.mounted_ship_data.collection_name, data.mounted_ship_data.nft_id);
                } else {
                    setMountedShip(false);
                    setMountedShipData(null);
                    setTraits([]);
                    setImageUrl('');
                }

                setHangerData(data.hanger_data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch hanger data');
                setLoading(false);
            }
        };

        fetchHangerData();
    }, [userInfo]);

    const fetchImage = (walletAddress, collectionName, nftId) => {
        const payload = {
            wallet_address: walletAddress,
            collection_name: collectionName,
            nft_id: nftId,
            token: userInfo.LoginKey
        };

        fetch(`${BASE_URL}/get_image`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.blob())
            .then(imageBlob => {
                const imageObjectURL = URL.createObjectURL(imageBlob);
                setImageUrl(imageObjectURL);
            })
            .catch(() => {
                setImageUrl('');
            });
    };

    return (
        <div className="fighterdetails-container" key={forceRender}>
            <div className="fighterdetails-content">
                <div className="fighterdetails-image-container">
                    {imageUrl ? (
                        <img src={imageUrl} alt="Ship" className="fighterdetails-image" />
                    ) : (
                        <img src={staticImage} alt="No Mounted Fighter" className="fighterdetails-image" />
                    )}
                </div>
            </div>

            <div className="fighterdetails-stats-container">
                {hangerData.map((stat, index) => (
                    <div key={index} className="fighterdetails-stat">
                        {Object.keys(stat).map((key) => (
                            <div key={key}>
                                {key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}: {stat[key]}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {mountedShip && traits.length > 0 && mountedShipData && (
                <div className="fighterdetails-traits-container">
                    <h3>
                        {mountedShipData.collection_name.charAt(0).toUpperCase() + mountedShipData.collection_name.slice(1)}{" "}
                        Fighter #{mountedShipData.nft_id} Traits:
                    </h3>

                    <ul className="fighterdetails-traits-list">
                        {traits.map((trait, index) => (
                            <li key={index}>
                                <strong>{trait.trait_type}:</strong>
                                <span className="fighterdetails-trait-value">{trait.value}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FighterDetails;
