import React, { useState } from 'react';
import './FightersActionsPage.css';
import FightersMount from './FightersMount/FightersMount';
import FightersRent from './FightersRent/FightersRent';
import FightersTransfer from './FightersTransfer/FightersTransfer';
import FightersSell from './FightersSell/FightersSell';

const ActionsPage = ({ fighter, nftData, globalMount, setGlobalMount }) => {
    const [selectedTab, setSelectedTab] = useState(''); // Track the selected tab

    const tabs = ['Mount', 'Rent', 'Transfer', 'Sell'];

    const renderTabContent = () => {
        switch (selectedTab) {
            case 'Mount':
                return <FightersMount fighter={fighter} nftData={nftData} globalMount={globalMount} setGlobalMount={setGlobalMount} />;
            case 'Rent':
                return <FightersRent fighter={fighter} />;
            case 'Transfer':
                return <FightersTransfer fighter={fighter} />;
            case 'Sell':
                return <FightersSell fighter={fighter} />;
            default:
                return <p className="select-action-message">Select Action</p>;
        }
    };

    return (
        <div className="actions-page">
            <div className="actions-tabs">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`tab-button ${selectedTab === tab ? 'active-tab' : ''}`}
                        onClick={() => setSelectedTab(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className="actions-content">{renderTabContent()}</div>
        </div>
    );
};

export default ActionsPage;