import React from 'react';
import './FightersRent.css';

const FightersRent = ({ fighter }) => {
    const PROX_PRICE = 25; // Example price in PROX

    return (
        <div className="rent-container">
            <h4 className="rent-header"></h4>
            {fighter.is_mounted?.trim().toLowerCase() === 'yes' ? (
                <p className="rent-error-message">
                    Cannot Rent, Fighter in Game Contract
                </p>
            ) : (
                <>
                    <p className="rent-price">Price: {PROX_PRICE} PROX</p>
                    <button className="rent-button" onClick={() => console.log(`Renting Fighter ID: ${fighter.token_id} for ${PROX_PRICE} PROX`)}>
                        Rent
                    </button>
                    <button className="rent-button" onClick={() => console.log(`Removing rental for Fighter ID: ${fighter.token_id}`)}>
                        Remove Rental
                    </button>
                </>
            )}
        </div>
    );
};

export default FightersRent;
