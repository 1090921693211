import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../UserContext';
import { useLogLogin } from './useLogLogin';

import { BASE_URL } from '../apiConfig';

const multiVACNetwork = {
    chainId: '62621',
    chainName: 'MultiVAC Mainnet',
    nativeCurrency: {
        name: 'MultiVAC',
        symbol: 'MTV',
        decimals: 18,
    },
    rpcUrls: ['https://rpc.mtv.ac'],
    blockExplorerUrls: ['https://e.mtv.ac'],
};

export function useHandleLogin() {
    const { updateUser } = useContext(UserContext);
    const navigate = useNavigate();
    const logLogin = useLogLogin();

    const handleLogin = async () => {
        try {
            if (window.ethereum) {
                const hexChainId = `0x${parseInt(multiVACNetwork.chainId).toString(16)}`;
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: hexChainId }],
                });
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];

                const url = `${BASE_URL}/check_subscriber?address=${account}`;
                const response = await fetch(url);
                const data = await response.json();

                if (data.is_subscriber) {
                    const loginSuccess = await logLogin(account);
                    if (!loginSuccess) {
                        console.log("Login attempt not logged, user likely cancelled.");
                        return;
                    }
                    updateUser({ accounts: account });
                    navigate('/loading');
                } else {
                    navigate('/subscription');
                }
            } else {
                console.log("MetaMask is not installed!");
            }
        } catch (error) {
            console.error(error);
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: `0x${parseInt(multiVACNetwork.chainId).toString(16)}`,
                            chainName: multiVACNetwork.chainName,
                            nativeCurrency: multiVACNetwork.nativeCurrency,
                            rpcUrls: multiVACNetwork.rpcUrls,
                            blockExplorerUrls: multiVACNetwork.blockExplorerUrls
                        }]
                    });
                    await handleLogin();
                } catch (addError) {
                    console.error("Failed to add the network:", addError);
                }
            }
        }
    };

    return handleLogin;
}
