import { useEffect } from 'react';
import Web3 from 'web3';
import multiVACNetwork from './MultiVACNetwork';

const useSwitchNetwork = (isConnected) => {
    useEffect(() => {
        const hexChainId = `0x${parseInt(multiVACNetwork.chainId, 10).toString(16)}`;
        const switchNetwork = async () => {
            if (window.ethereum && isConnected) {
                try {
                    
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: hexChainId }],
                    });
                } catch (switchError) {
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: hexChainId,
                                    chainName: multiVACNetwork.chainName,
                                    nativeCurrency: multiVACNetwork.nativeCurrency,
                                    rpcUrls: multiVACNetwork.rpcUrls,
                                    blockExplorerUrls: multiVACNetwork.blockExplorerUrls
                                }],
                            });
                        } catch (addError) {
                            console.error('Failed to add the network:', addError);
                        }
                    } else {
                        console.error('Failed to switch the network:', switchError);
                    }
                }
            }
        };

        if (isConnected) {
            switchNetwork();
        }
    }, [isConnected]);
};

export default useSwitchNetwork;