import { useContext } from 'react';
import { useWeb3 } from './Web3Context';
import UserContext from '../UserContext';
import loginContract from './../config/LoginContract';

//This script handles the login contract on MultiVAC

export const useLogLogin = () => {
    const { updateUser } = useContext(UserContext);
    const web3 = useWeb3();

    const abi = [
        {
            "inputs": [],
            "name": "logLogin",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ];

    const logLogin = async (account) => {
        if (!web3 || !account) {
            console.log("Web3 is not initialized or no account is connected.");
            return false;  // Indicate failure
        }

        const contract = new web3.eth.Contract(abi, loginContract.loginContractAddress);

        try {
            const gasPrice = await web3.eth.getGasPrice();
            const transactionResponse = await contract.methods.logLogin().send({
                from: account,
                gasPrice: gasPrice
            });

            console.log('Login logged successfully. Transaction:', transactionResponse.transactionHash);
            if (updateUser) {
                updateUser({ lastLogin: new Date().toISOString() });
            }
            return true;  // Indicate success
        } catch (error) {
            console.error('Login logging failed:', error);
            return false;  // Indicate failure
        }
    };

    return logLogin;
};
