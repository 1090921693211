import { useCallback } from 'react';
import { useWeb3 } from './Web3Context';
import abi from './general_abi';
import subContract from './../config/SubscriptionContract';

const useCheckAllowance = (account, setIsApproved) => {
    const { web3 } = useWeb3(); // Ensure that web3 is destructured correctly

    const checkAllowance = useCallback(async () => {
        if (web3 && account) {
            const proxContract = new web3.eth.Contract(abi, '0x9029d1dEDa00e166De55406eAB2608E4efC66D7b');
            const allowance = await proxContract.methods.allowance(account, '0xF7C07a5CD7C2eB2337C2E585821b09a9387eE703').call();
            // Use toBN if available, otherwise check your web3 version for the correct method
            const isApproved = web3.utils.toBN(allowance).gt(web3.utils.toBN('0')); // Ensure toBN is used correctly
            setIsApproved(isApproved);
            return allowance;
        }
        return null;
    }, [web3, account, setIsApproved]);

    return checkAllowance;
};

export default useCheckAllowance;
