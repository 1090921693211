import React, { useState, useContext } from 'react';
import './UnmountComponent.css';
import BN from 'bn.js';
import Web3 from 'web3';
import UserContext from '../../../../UserContext';

import { BASE_URL, MTV_NETWORK, FighterMOUNT_CONTRACT, PROX_TOKEN } from '../../../../apiConfig';
import { CONTRACT_ABI, ERC20_ABI } from './MountAbi';

const UnmountComponent = ({ fighter, mount_ship_price }) => {
    const [stepStatus, setStepStatus] = useState('');
    const [apiError, setApiError] = useState(null);
    const [showButton, setShowButton] = useState(true); // New state to control button visibility
    const { userInfo } = useContext(UserContext);

    const handleUnmount = async () => {
        try {
            setShowButton(false); // Hide the button once clicked
            if (!window.ethereum) throw new Error('MetaMask is not installed!');

            const web3 = new Web3(window.ethereum);

            setStepStatus('Switching to MultiVAC network...');
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${MTV_NETWORK.chainId.toString(16)}` }],
            });

            const accounts = await web3.eth.getAccounts();
            const account = accounts[0];

            const contractAddress = FighterMOUNT_CONTRACT;
            const contract = new web3.eth.Contract(CONTRACT_ABI, contractAddress);

            const nftContractAddress = fighter.contract_address.toLowerCase();
            const proxTokenContract = new web3.eth.Contract(ERC20_ABI, PROX_TOKEN);
            const proxValue = web3.utils.toWei(mount_ship_price.toString(), 'ether');

            if (!fighter.token_id || isNaN(fighter.token_id)) {
                throw new Error('Invalid NFT ID provided.');
            }

            setStepStatus('Checking PROX token allowance...');
            const allowance = await proxTokenContract.methods.allowance(account, contractAddress).call();


            // Convert allowance and proxValue to BN for accurate comparison
            const allowanceBN = new BN(allowance);
            const proxValueBN = new BN(proxValue);


            if (allowanceBN.lt(proxValueBN)) {
                console.log('Allowance insufficient, initiating approval...');
                setStepStatus('Approving PROX token...');
                await proxTokenContract.methods.approve(contractAddress, proxValue).send({
                    from: account,
                    gasPrice: await web3.eth.getGasPrice(),
                });
            } else {
                console.log('Sufficient allowance found. Skipping approval.');
            }

            setStepStatus('Estimating gas and sending unmount transaction...');
            const gasEstimate = await contract.methods
                .unmountNFT(nftContractAddress, parseInt(fighter.token_id)) // Ensure correct parameters
                .estimateGas({ from: account });
            const gasPrice = await web3.eth.getGasPrice();

            await contract.methods.unmountNFT(nftContractAddress, parseInt(fighter.token_id)).send({
                from: account,
                gas: gasEstimate,
                gasPrice,
            });

            setStepStatus('Waiting for transaction confirmation...');
            console.log('NFT successfully unmounted!');

            // After the blockchain transaction, call the unmount API to delete the entry in MongoDB
            const requestData = {
                wallet_address: account,
                collection_name: fighter.collection_name,
                nft_id: fighter.token_id,
                token: userInfo.LoginKey,
            };

            const response = await fetch(`${BASE_URL}/unmount_ship`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const result = await response.json();
                if (result.unmounted) {
                    setStepStatus('Unmount successful!');
                } else {
                    setStepStatus('Unmount unsuccessful.');
                }
            } else {
                setStepStatus('Error communicating with the server.');
            }
        } catch (error) {
            console.error('Error unmounting NFT:', error.message);
            setApiError(error.message || 'Failed to unmount the NFT');
            setStepStatus('Error occurred during unmounting.');
            setShowButton(true); // Show the button again if an error occurs
        }
    };

    return (
        <div>
            {mount_ship_price && (
                <p className="mount-ship-price">
                    Unmount Ship Price: {mount_ship_price} PROX
                </p>
            )}
            {stepStatus && (
                <p className={`unstep-status-message ${apiError ? 'error' : ''}`}>
                    {stepStatus}
                </p>
            )}
            {showButton && (
                <button className="confirm-unmount-button" onClick={handleUnmount}>
                    Confirm Unmount
                </button>
            )}
        </div>
    );
};

export default UnmountComponent;