import { useState, useEffect, useCallback } from 'react';
import Web3 from 'web3';
import useSwitchNetwork from './SwitchNetwork';

const useConnectToMetaMask = () => {
    const [account, setAccount] = useState(null);
    const [web3, setWeb3] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [error, setError] = useState(null);

    // Integrate the network switching here
    useSwitchNetwork(isConnected);

    const connect = useCallback(async () => {
        if (window.ethereum) {
            try {
                const w3 = new Web3(window.ethereum);
                setWeb3(w3);

                const accounts = await w3.eth.requestAccounts();
                if (accounts.length > 0) {
                    setAccount(accounts[0]);
                    setIsConnected(true);
                } else {
                    setError('No accounts found.');
                }
            } catch (err) {
                setError('Failed to connect to MetaMask: ' + err.message);
            }
        } else {
            setError('MetaMask is not installed.');
        }
    }, []);

    return { account, web3, isConnected, error, connect };
};

export default useConnectToMetaMask;