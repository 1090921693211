import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../../UserContext';
import './FightersPage.css';
import { BASE_URL } from '../../../apiConfig';
import DetailsPage from './FightersDetailsPage';
import ActionsPage from './FightersActionsPage';
import { filterNFTData } from './searchUtils'; // Import the search function

const collectionMapping = {
    "Tellurian Fighters": "tellurian",
    "Celestial Fighters": "celestial",
    "Machina Fighters": "machina",
};

const FightersPage = ({ subCategory, setLoading }) => {
    const [nftData, setNftData] = useState([]);
    const [globalMount, setGlobalMount] = useState('no'); // State to store global_mount
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFighter, setSelectedFighter] = useState(null);
    const [contractAddress, setContractAddress] = useState('');
    const [searchError, setSearchError] = useState(null); // Updated to store error message
    const { userInfo } = useContext(UserContext);


    useEffect(() => {
        const fetchNFTs = async () => {
            if (!subCategory) return;

            setNftData([]); // Reset data while fetching
            setFilteredData([]);
            setSelectedFighter(null);
            setSearchError(null);

            const collectionName = collectionMapping[subCategory] || subCategory.toLowerCase();

            try {
                const requestBody = {
                    wallet_address: userInfo.accounts,
                    token: userInfo.LoginKey,
                    collection_name: collectionName,
                };

                const response = await fetch(`${BASE_URL}/get_wallet_asset_details`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestBody),
                });

                if (response.status === 429) {
                    setSearchError('A search is already in progress. Please wait and try again.');
                    return;
                }

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const data = await response.json();

                if (!data.token_data || data.token_data.length === 0) {
                    setSearchError('No assets found for the selected category.');
                    return;
                }

                setContractAddress(data.contract_address || '');
                setGlobalMount(data.global_mount || 'no');

                const sortedData = (data.token_data || []).sort((a, b) => {
                    if (a.is_mounted === "yes" && b.is_mounted !== "yes") return -1;
                    if (a.is_mounted !== "yes" && b.is_mounted === "yes") return 1;
                    return parseInt(a.token_id) - parseInt(b.token_id);
                });

                console.log("Sorted Data:", sortedData);
                setNftData(sortedData);
                setFilteredData(sortedData); // Initialize filteredData
            } catch (error) {
                console.error('Failed to fetch assets:', error);
                setSearchError('Failed to fetch assets. Please try again later.');
            } finally {
                if (typeof setLoading === 'function') {
                    setLoading(false);
                }
            }
        };

        fetchNFTs();
    }, [subCategory, userInfo.accounts, userInfo.LoginKey, setLoading]);

    const handleFighterClick = (fighter) => {
        const collectionName = collectionMapping[subCategory] || subCategory.toLowerCase();

        setSelectedFighter({
            ...fighter,
            wallet_address: userInfo.accounts,
            collection_name: collectionName,
            contract_address: contractAddress,
            token: userInfo.LoginKey,
            is_mounted: fighter.is_mounted || 'no',
            is_rented: fighter.is_rented || 'no',
        });
    };

    useEffect(() => {
        const result = filterNFTData(searchTerm, nftData);
        setFilteredData(result);
    }, [searchTerm, nftData]);

    const getStatusClass = (nft) => {
        if ((nft.is_mounted || '').trim().toLowerCase() === 'yes') return 'fighter-status-mounted';
        if ((nft.is_rented || '').trim().toLowerCase() === 'yes') return 'fighter-status-rented';
        return 'fighter-status-default';
    };

    const getCollectionLabel = () => {
        const labelPrefix = subCategory.split(" ")[0];
        return `${labelPrefix} Fighter ID`;
    };

    return (
        <div className="fighters-page">
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search by ID, traits (e.g., 42 or Scud, Ruby, MultiVAC, Ghost)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="fighters-content-layout">
                <div className="fighters-column1">
                    {searchError ? (
                        <p className="fighterapierror-message">{searchError}</p>
                    ) : filteredData.length === 0 ? (
                        <p className="loading-message">Please Wait.... Searching Assets...</p>
                    ) : (
                        filteredData.map((nft, index) => (
                            <div
                                key={`${nft.token_id}-${index}`}
                                className={"fighters-container"}
                                onClick={() => handleFighterClick(nft)}
                            >
                                <h3 className="fighter-id">{getCollectionLabel()}: {nft.token_id}</h3>
                                <div className={`fighter-status ${getStatusClass(nft)}`}>
                                    {nft.is_mounted?.trim().toLowerCase() === 'yes' && <p>Mounted</p>}
                                    {nft.is_rented?.trim().toLowerCase() === 'yes' && <p>Rented</p>}
                                    {nft.is_mounted?.trim().toLowerCase() !== 'yes' && nft.is_rented?.trim().toLowerCase() !== 'yes' && <p>In Wallet</p>}
                                </div>

                                <div className="fighters-traits">
                                    <div className="traits-grid">
                                        {nft.traits.map((trait, traitIndex) => (
                                            <div key={traitIndex} className="trait-item">
                                                <div className="trait-name">{trait.trait_type}</div>
                                                <div className="trait-value">{trait.value}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div className="fighters-column2">
                    <div className="fighters-details">
                        {selectedFighter ? (
                            <DetailsPage
                                fighter={selectedFighter}
                                traits={selectedFighter.traits}
                            />
                        ) : (
                            'Please Select Fighter'
                        )}
                    </div>
                    <div className="fighters-actions">
                        {selectedFighter ? (
                            <ActionsPage fighter={selectedFighter} nftData={nftData} globalMount={globalMount} setGlobalMount={setGlobalMount} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FightersPage;
