import React from 'react';
import './FightersSell.css';

const FightersSell = ({ fighter }) => {
    return (
        <div className="sell-container">
            <h4 className="sell-header"></h4>
            {fighter.is_mounted?.trim().toLowerCase() === 'yes' ? (
                <p className="sell-error-message">
                    Cannot Sell, Fighter in Game Contract
                </p>
            ) : (
                <>
                    <button className="sell-button" onClick={() => console.log(`Selling Fighter ID: ${fighter.token_id}`)}>
                        Sell
                    </button>
                    <button className="sell-button" onClick={() => console.log(`Canceling sale for Fighter ID: ${fighter.token_id}`)}>
                        Cancel Sale
                    </button>
                </>
            )}
        </div>
    );
};

export default FightersSell;
