import { useContext } from 'react';
import { useWeb3 } from './Web3Context';
import abi from './general_abi'; // This should include the paySubscription method
import subContract from './../config/SubscriptionContract';



const useSubscription = () => {
    const web3 = useWeb3();

    const handleSubscription = async (account) => {
        if (!web3 || !account) {
            console.log("Web3 is not initialized or no account is connected.");
            return false;
        }

        const contract = new web3.eth.Contract(abi, subContract.subscriptionContract);

        try {
            const gasPrice = await web3.eth.getGasPrice();
            // Call the paySubscription function on the subscription contract
            const transactionResponse = await contract.methods.paySubscription().send({
                from: account,
                gasPrice: gasPrice
            });

            console.log('Subscription purchased successfully. Transaction:', transactionResponse.transactionHash);
            return true;
        } catch (error) {
            console.error('Subscription process failed:', error);
            return false;
        }
    };

    return handleSubscription;
};

export default useSubscription;
