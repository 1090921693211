import React from 'react';
import './ResourcePage.css';

const ResourcesPage = () => {
    return (
        <div className="resources-content-layout">
            <div className="resources-main-column">
                <div className="resources-container">Resource 1</div>
                <div className="resources-container">Resource 2</div>
                <div className="resources-container">Resource 3</div>
            </div>
            <div className="resources-side-column">
                <div className="resources-side-container">Details</div>
            </div>
        </div>
    );
};

export default ResourcesPage;