import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoadingPage.css';
import UserContext from './UserContext';
import { BASE_URL } from './apiConfig';

function LoadingPage() {
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const { userInfo, updateUser } = useContext(UserContext);

    const fetchDataFromBackend = async () => {
        setStatus('Obtaining Security Clearance...');

        if (!userInfo?.accounts) {
            console.warn('No user account detected. Redirecting to root.');
            navigate('/');
            return;
        }

        const requestData = { account: userInfo.accounts };
        console.log('Sending the following JSON to the backend:', JSON.stringify(requestData));

        try {
            const response = await fetch(`${BASE_URL}/get_login_key`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                console.error('Network response was not ok', await response.text());
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            // Check if token exists
            if (data.token) {
                updateUser({ LoginKey: data.token });
                setStatus('Security Authorization Granted');
                setTimeout(() => navigate('/mainpage'), 3000); // Proceed to main page after 3 seconds
            } else {
                throw new Error('Token not found in response');
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setStatus('Security Authorization Failed');
            setTimeout(() => navigate('/'), 3000); // Redirect to root after failure
        }
    };

    useEffect(() => {
        fetchDataFromBackend();
    }, []); // No unnecessary dependencies; run once on mount

    return (
        <div className="entry-loading-container">
            <h1 className="entry-loading-title">Loading...</h1>
            <p className="entry-loading-status">{status}</p> {/* Display current status */}
        </div>
    );
}

export default LoadingPage;
