import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../apiConfig';
import './BotsDetailsPage.css';

const BotsDetailsPage = ({ fighter }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const requestBody = {
                    wallet_address: fighter.wallet_address,
                    collection_name: fighter.collection_name,
                    token: fighter.token,
                    nft_id: fighter.token_id,
                };

                const response = await fetch(`${BASE_URL}/get_image`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to fetch image');
                }

                const blob = await response.blob();
                const imageUrl = URL.createObjectURL(blob);
                setImageSrc(imageUrl);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchImage();
    }, [fighter]);

    const fighterStats = [
        "STAT",
        "STAT",
        "STAT",
        "STAT",
        "STAT",
        "STAT",
        "STAT",
        "STAT",
        "STAT",
    ];

    return (
        <div className="bots-details-page">
            <h3>ProxBot ID: {fighter.token_id}</h3>
            <div className="bots-details-image-container">
                {loading ? (
                    <p>Loading image...</p>
                ) : error ? (
                    <p className="bots-error-message">Error: {error}</p>
                ) : (
                    <img
                        src={imageSrc}
                        alt={`NFT for ProxBot ID ${fighter.token_id}`}
                        className="bots-details-image"
                    />
                )}
            </div>

            <div className="bots-details-stats">
                {fighterStats.map((stat, index) => (
                    <div key={index} className="bots-stat-container">
                        <span className="bots-stat-name">{stat}</span>
                        <span className="bots-stat-value">REDACTED</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BotsDetailsPage;
