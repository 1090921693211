import React, { useState } from 'react';
import './HangerPage.css';
import HangerTopBar from './HangerTopBar';
import HangerSubBar from './HangerSubBar';
import FighterDetails from './FighterDetails';

const HangerPage = () => {
    const [category, setCategory] = useState(null);
    const [subcategory, setSubcategory] = useState(null);

    const handleTopBarClick = (selectedCategory) => {
        setCategory(selectedCategory);
        setSubcategory(null);
    };

    const handleSubBarClick = (selectedSubcategory) => {
        setSubcategory(selectedSubcategory);
    };

    const renderContent = () => {
        if (subcategory === 'Overview') {
            return <FighterDetails />;
        } else if (subcategory === 'Available Fighters') {
            return <div>Available Fighters Content Goes Here</div>;
        } else if (subcategory === 'Equipment') {
            return <div>Equipment Content Goes Here</div>;
        }
        return <p></p>;
    };

    return (
        <div className="hanger-container">
            <HangerTopBar onButtonClick={handleTopBarClick} />
            {category && (
                <>
                    <HangerSubBar category={category} onButtonClick={handleSubBarClick} />
                    <div className="content-container">{renderContent()}</div>
                </>
            )}
        </div>
    );
};

export default HangerPage;
