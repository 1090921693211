import React from 'react';

const FightersRent = ({ fighter }) => {
    const PROX_PRICE = 25; // Example price in PROX

    const handleRent = () => {
        console.log(`Renting Fighter ID: ${fighter.token_id} for ${PROX_PRICE} PROX`);
        // Implement rent logic here
    };

    const handleRemoveRental = () => {
        console.log(`Removing rental for Fighter ID: ${fighter.token_id}`);
        // Implement remove rental logic here
    };

    return (
        <div>
            <h4>Rental Actions</h4>
            <p>Price: {PROX_PRICE} PROX</p>
            <button onClick={handleRent}>Rent</button>
            <button onClick={handleRemoveRental}>Remove Rental</button>
        </div>
    );
};

export default FightersRent;