import React, { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import './SideBar.css';
import headLogo from '../Images/headLogo.png';
import UserContext from '../UserContext';
import { BASE_URL } from '../apiConfig';

function Sidebar() {
    const { userInfo } = useContext(UserContext);
    const [isAdmin, setIsAdmin] = useState(false);
    const walletConnected = userInfo.accounts && userInfo.accounts.length > 0;
    const walletAddress = walletConnected ? `${userInfo.accounts.slice(0, 4)}...${userInfo.accounts.slice(-4)}` : 'No Wallet';

    useEffect(() => {
        const checkAdminAccess = async () => {
            if (userInfo.accounts && userInfo.LoginKey) {
                try {
                    const response = await fetch(`${BASE_URL}/admin`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            wallet_address: userInfo.accounts,
                            token: userInfo.LoginKey,
                        }),
                    });
                    const data = await response.json();
                    if (response.ok && data.admin) {
                        setIsAdmin(true);
                    }
                } catch (error) {
                    console.error('Failed to check admin access:', error);
                }
            }
        };

        checkAdminAccess();
    }, [userInfo.accounts, userInfo.LoginKey]);

    return (
        <div className="sidebar-container">
            <div className="sidebar">
                <img src={headLogo} alt="Logo" />
                <div className={`wallet-address ${!walletConnected ? 'no-wallet' : ''}`}>
                    {walletAddress}
                </div>

                <div className="sidebar-links-wrapper">
                    <NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Back To Login
                    </NavLink>

                    <NavLink to="/mainpage/accountpage" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Account Stats
                    </NavLink>

                    <NavLink to="/mainpage/game" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Play Game
                    </NavLink>

                    <NavLink to="/mainpage/circsupply" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Tokenomics
                    </NavLink>

                    <NavLink to="/mainpage/assetpage" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Proxima Assets
                    </NavLink>

                    <NavLink to="/mainpage/hangerpage" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                        Hanger
                    </NavLink>

                    {isAdmin && (
                        <NavLink to="/mainpage/admin" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                            Admin
                        </NavLink>
                    )}
                </div>
            </div>


        </div>
    );
}

export default Sidebar;
